<template>
    <div class="view home">
        <div style="padding: 1rem; ">
            <div class="columns">
                <div class="column is-4">
                    <div class="card">
                        <header class="card-header">
                            <p class="card-header-title">
                                📈Monthly Sales
                            </p>
                        </header>
                        <div class="card-content">
                            <div class="content is-large has-text-centered">
                                <h6 class="has-text-weight-normal"
                                    style="margin-bottom: 0.5rem; ">
                                    Totalling
                                </h6>
                                <h1 class="is-marginless">
                                    HK${{ monthlyRevenue }}
                                </h1>
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a href="#" class="card-footer-item">
                                📥Download Report
                            </a>
                        </footer>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card">
                        <header class="card-header">
                            <p class="card-header-title">
                                📉Monthly Expenditure
                            </p>
                        </header>
                        <div class="card-content">
                            <div class="content is-large has-text-centered">
                                <h6 class="has-text-weight-normal"
                                    style="margin-bottom: 0.5rem; ">
                                    Totalling
                                </h6>
                                <h1 class="is-marginless">
                                    HK$350
                                </h1>
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a href="#" class="card-footer-item">
                                ✏️Set Expenditures
                            </a>
                        </footer>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card">
                        <header class="card-header">
                            <p class="card-header-title">
                                💵Monthly Profit/Loss
                            </p>
                        </header>
                        <div class="card-content">
                            <div class="content is-large has-text-centered">
                                <h6 class="has-text-weight-normal"
                                    style="margin-bottom: 0.5rem; ">
                                    Totalling
                                </h6>
                                <h1 class="is-marginless">
                                    HK${{ monthlyRevenue - 350 }}
                                </h1>
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a href="#" class="card-footer-item">
                                📥Download Analysis
                            </a>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "home",
    components: {
        NavBar: () => import("../components/layouts/NavBar.vue"),
    },
    data () {
        return {

        };
    },
    computed: {
        orders () {
            return this.$store.getters["shop/orders"]
                .slice(0);
        },
        monthlyRevenue () {
            console.log(this.orders);
            return this.orders
                .filter(
                    ({ createdAt }) => {
                        const temp = new Date(createdAt);
                        const today = new Date();
                        return temp.getFullYear() === today.getFullYear() && temp.getMonth() === 10;
                    }
                )
                .reduce(
                    (acc, { orderTotal }) => acc += parseFloat(orderTotal),
                    0
                );
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.home {

}
</style>
